import styled from "styled-components";

type GenericPopoverStylesProps = {
  height: number;
  width: number;
};

export const PopoverElementBacker = styled.div.attrs(() => ({
  className: "bcr-popover-element",
}))<GenericPopoverStylesProps>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: absolute;
  z-index: -1;
`;
