import { useMediaQuery } from "react-responsive";
import { useTheme } from "./useTheme";

type useBreakpointsType = {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isDesktopTablet: boolean;
  isTabletOrMobile: boolean;
  isPortrait: boolean;
  isRetina: boolean;
};

export const useBreakpoints = (): useBreakpointsType => {
  const { Theme } = useTheme();
  const isMobile: boolean = useMediaQuery(Theme.breakpoints.mobile);
  const isTablet: boolean = useMediaQuery(Theme.breakpoints.tablet);
  const isDesktop: boolean = useMediaQuery(Theme.breakpoints.desktop);
  const isDesktopTablet: boolean = useMediaQuery(Theme.breakpoints.desktopTablet);
  const isTabletOrMobile: boolean = useMediaQuery(Theme.breakpoints.tabletMobile);
  const isPortrait: boolean = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina: boolean = useMediaQuery({ query: "(min-resolution: 2dppx)" });

  return { isMobile, isTablet, isDesktop, isDesktopTablet, isTabletOrMobile, isPortrait, isRetina };
};
