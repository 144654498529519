import styled from "styled-components";
import { LoadingThemeType } from "../../types";

type LoadingStylesProps = {
  styles: LoadingThemeType;
};

export const Parent = styled.div<LoadingStylesProps>`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
`;

export const Overlay = styled.div<LoadingStylesProps>`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.styles.background};
  opacity: ${(props) => props.styles.opacity};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingLabel = styled.div<LoadingStylesProps>`
  color: ${(props) => props.styles.color};
  text-align: center;
  font-size: ${(props) => props.styles.fontSize};
  margin-top: 0.5rem;
`;

type LoadingAnimationProps = {
  animated?: boolean;
};

export const LoadingAnimation = styled.div<LoadingAnimationProps>`
  &&& {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: ${(props) => (props.animated ? "pulse 1.5s linear infinite" : "none")};
    animation-delay: 1ms;

    @keyframes pulse {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0.4;
      }
    }
  }
`;

export const LoaderImage = styled.img`
  &&& {
    width: ${(props) => props.width};
    height: auto;
  }
`;
