export const getElement = (selector: string): HTMLElement | null =>
  !!document ? document.getElementById(selector) || document.querySelector(selector) : null;

export const getElementsByClassName = (selector: string): HTMLCollectionOf<Element> | null =>
  !!document ? document.getElementsByClassName(selector) : null;

export const getFirstElementByClassName = (selector: string): Element | null => {
  if (!!document) {
    const elements = document.getElementsByClassName(selector);
    return elements.length ? elements[0] : null;
  }
  return null;
};

export const isElementOverflowed = (el: HTMLElement): boolean => el.clientWidth < el.scrollWidth;
