import clsx from "clsx";
import React, { useEffect, useLayoutEffect, useReducer, useRef, useState } from "react";
import { ArrowContainer, Popover, PopoverAlign, PopoverPosition } from "react-tiny-popover";
import { useDevices } from "../../../hooks";
import { getElementsByClassName, getFirstElementByClassName, uniqueStr } from "../../../utils";
import { PopoverElementBacker } from "./GenericPopoverStyles";

export type TriggerTypes = "hover" | "click";

export const Triggers = {
  HOVER: "hover" as TriggerTypes,
  CLICK: "click" as TriggerTypes,
};

const popoverClassName = "bcr-popover";

type setIsOpenType = (value: boolean) => void;

export interface GenericPopoverProps {
  children: React.ReactNode;
  trigger?: TriggerTypes;
  location?: PopoverPosition;
  align?: PopoverAlign;
  delay?: number;
  disabled?: boolean;
  singleOpen?: boolean;
  arrowColor?: string;
  showArrow?: boolean;
  portal?: HTMLElement;
  setIsOpen?: setIsOpenType;
  reposition?: boolean;
}

export type PopoverRef = HTMLElement;

export interface GenericPopoverFinalProps extends GenericPopoverProps {
  element: React.RefObject<PopoverRef>;
}

type GenericPopoverState = {
  open: boolean;
  timeoutRef?: ReturnType<typeof setTimeout>;
  setIsOpen?: setIsOpenType;
};

type ActionTypes =
  | { type: "closePopover" }
  | { type: "toggleOpen" }
  | { type: "setIsOpen"; payload?: setIsOpenType }
  | { type: "setOpen"; payload: boolean }
  | { type: "setTrigger"; payload: TriggerTypes }
  | { type: "setTimeoutRef"; payload: ReturnType<typeof setTimeout> | undefined };

const initState: GenericPopoverState = {
  open: false,
  timeoutRef: undefined,
};

const popoverClosedClass = "bcr-popover-closed";

function reducer(state: GenericPopoverState, action: ActionTypes) {
  switch (action.type) {
    case "setIsOpen":
      return { ...state, setIsOpen: action.payload };
    case "closePopover": {
      if (state.timeoutRef) {
        clearTimeout(state.timeoutRef);
      }
      if (!!state.setIsOpen) {
        state.setIsOpen(false);
      }
      return { ...state, open: false, timeoutRef: undefined };
    }
    case "toggleOpen": {
      const newOpenValue = !state.open;
      if (!!state.setIsOpen) {
        state.setIsOpen(newOpenValue);
      }
      return { ...state, open: newOpenValue };
    }
    case "setOpen": {
      const newOpenValue = action.payload;
      if (!!state.setIsOpen) {
        state.setIsOpen(newOpenValue);
      }
      return { ...state, open: newOpenValue };
    }
    case "setTimeoutRef": {
      return { ...state, timeoutRef: action.payload };
    }
    default:
      return state;
  }
}

const GenericPopover: React.FC<GenericPopoverFinalProps> = ({
  children,
  delay = 0,
  trigger = "hover",
  location = "top",
  align = "center",
  disabled = false,
  singleOpen = true,
  portal = document.body,
  arrowColor = "",
  showArrow = false,
  reposition,
  setIsOpen,
  element,
}) => {
  const { isMobile } = useDevices();
  const [popoverId] = useState<string>(`bcr-popover-${uniqueStr()}`);
  const popoverRef = useRef<HTMLDivElement>(null);
  const [elementWidth, setElementWidth] = useState<number>(0);
  const [elementHeight, setElementHeight] = useState<number>(0);
  const [finalTrigger, setFinalTrigger] = useState<TriggerTypes>(trigger);
  const [state, dispatch] = useReducer(reducer, initState);

  function togglePopover(event: Event) {
    const elements: HTMLCollectionOf<Element> | null = getElementsByClassName(popoverClassName);
    if (!state.open && singleOpen && !!elements) {
      for (const element of Array.from(elements)) {
        if (!!element && !element.className.includes(popoverClosedClass)) {
          try {
            element.className = `${element.className} ${popoverClosedClass}`;
          } catch (e) {
            console.error("Error removing popovers", e);
          }
        }
      }
    }
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();
    if (!disabled) {
      if (!!state.timeoutRef) {
        clearTimeout(state.timeoutRef);
      }
      const tRef = setTimeout(() => {
        dispatch({ type: "toggleOpen" });
        dispatch({ type: "setTimeoutRef", payload: undefined });
      }, delay);
      dispatch({ type: "setTimeoutRef", payload: tRef });
    }
  }

  const closePopover = (event: Event) => {
    event.stopPropagation();
    event.preventDefault();
    event.stopImmediatePropagation();
    dispatch({ type: "closePopover" });
  };

  const checkForOpen = (entries: ResizeObserverEntry[], observer: ResizeObserver) => {
    if (!!entries?.length) {
      const item = entries[0];
      if (item?.target?.clientHeight === 0) {
        item.target.className = item.target.className.replace(popoverClosedClass, "");
        dispatch({ type: "setOpen", payload: false });
      }
    }
  };

  useEffect(() => {
    if (!!element?.current) {
      setElementWidth(element.current.offsetWidth);
      setElementHeight(element.current.offsetHeight);
      if (finalTrigger === Triggers.HOVER) {
        element.current.onmouseover = togglePopover;
        element.current.onmouseout = closePopover;
      }
      if (finalTrigger === Triggers.CLICK) {
        element.current.onclick = togglePopover;
      }
    }
  }, [element, finalTrigger, state.open]);

  useEffect(() => {
    if (isMobile) {
      setFinalTrigger("click");
    } else {
      setFinalTrigger(trigger);
    }
  }, [trigger, isMobile]);

  useEffect(() => {
    dispatch({ type: "setIsOpen", payload: setIsOpen });
  }, [setIsOpen]);

  useLayoutEffect(() => {
    const d = getFirstElementByClassName(popoverId);
    let resizeObserver: ResizeObserver;
    if (d) {
      resizeObserver = new ResizeObserver(checkForOpen);
      resizeObserver.observe(d);
    }

    return () => resizeObserver?.disconnect();
  }, [popoverId, state.open]);

  return (
    <Popover
      containerClassName={clsx(popoverClassName, popoverId)}
      isOpen={state.open}
      align={align}
      positions={[location]}
      reposition={reposition}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={arrowColor}
          arrowSize={showArrow ? 10 : 0}
          className="bcr-popover-arrow-container"
          arrowClassName="bcr-popover-arrow"
        >
          {/*@ts-ignore*/}
          {children}
        </ArrowContainer>
      )}
      parentElement={portal}
      onClickOutside={(event) => {
        if (finalTrigger === Triggers.CLICK) {
          closePopover(event);
        }
      }}
    >
      <PopoverElementBacker height={elementHeight} width={elementWidth} id={popoverId} />
    </Popover>
  );
};

export default GenericPopover;
