import { isAndroid, isBrowser, isIE, isIOS, isMacOs, isMobile, isWindows } from "react-device-detect";

type UseDevicesType = {
  isComputer: boolean;
  isMobile: boolean;
  isMac: boolean;
  isWindows: boolean;
  isAndroid: boolean;
  isIOS: boolean;
  isIE: boolean;
};

export const useDevices = (): UseDevicesType => {
  return {
    isComputer: isBrowser,
    isMobile: isMobile,
    isMac: isMacOs,
    isWindows: isWindows,
    isAndroid: isAndroid,
    isIOS: isIOS,
    isIE: isIE,
  };
};
